.login-page-container {
    max-width: fit-content;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.logo-container {
    height: 100px;
    text-align: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.epa-logo-container {
    text-align: center;
    img {        
        max-width: 90%;
    }
}

.login-container {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    padding: 20px;
    /*width: 80%;*/
    background-color: #bfd1e8;
    position: relative;
    overflow: hidden;
    align-items: flex-start;
}

.working-with-banner {
    max-width: 1400px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 80px;
}

.working-with-banner p {
    font-family: "Poppins";
}

.working-with-banner img {
    margin: 14px;
    /* height: 80px; */
}

.login-inset {
    position: absolute;
    background-image: linear-gradient(to right, #fbf4d9, #ffe38f 50%);
    top: -50%;
    left: 60%;
    height: 200%;
    aspect-ratio: 1;
    border-radius: 50%;
}

.login-left {
    width: 60%;
    padding: 20px;
}

.login-right {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-message {
    z-index: 1000;
    width: 80%;
}

.login-message p {
    font-size: 20px;
    margin-inline: auto;
    font-weight: bold;
    text-align: center;
}

.login-left h1 {
    font-family: 'Poppins';
    font-size: 25px;
}

.login-left h2 {
    font-family: 'Poppins';
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #2260b0
}

.login-left h3 {
    font-family: 'Poppins';
    font-size: 18px;
    color: #2260b0;
    font-weight: 600;
}

.login-left p {
    font-family: 'Source Sans 3';
    color: #000;
}

.login-left span {
    color: #2260b0;
    font-weight: 800;
}

.form-style {
    width: 25rem;
}

@media (max-width: 768px) {
    
    .logo-container {
        width: 100%;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color:#2260b0;
        padding-top: 5px;
    }

    .login-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .login-left, .login-right {
        width: 100%;
        padding: 10px;
        order: 2;
    }

    .login-right {
        justify-content: center;
        order: 1;
    }

    .login-left h2 {
        text-align: center;
    }

    .login-inset {
        display: none;
    }

    .form-style {
        width: auto;
    }

    .mobile-center {
        text-align: center;
    }
}