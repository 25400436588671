.h1-heading {
    color: #278F2F;
    font-family: 'Trebuchet MS';
    font-size: 50px;
    text-align: center;
} 
.h2-heading {
    color: #278F2F;
    text-align: center;
    font-family: 'Trebuchet MS';
    font-size: 35px;
}
.h3-heading {
    color: #1E1515;
    font-size: 20px;
    font-weight: normal;
    font-family: 'Trebuchet MS';
}

hr.rounded {
    border-top: 3px solid #F0C035;
    border-radius: 8px;
    width: 10%;
    margin: auto;
}
.b
{
    color: #389ABE;
}
.page-container {
    width: 1024px;
    margin: auto;
    position:center;
}
.header {
    height: 100px;
    width: 100%;
    display: flex;
}
.header-text {
    padding-top: 30px;
    padding-left: 320px;
    padding-right:20px;
    float: right;
}
.header-estate-logo {
    max-height: 100px;
    width: auto;
}

.container-body {
    padding-top: 40px;
}

#body-report {
    padding-top:40px;
    display:flex;
    width:100%;
}
#body-left-column {
    flex: 1;
    padding-left: 10px;
    padding-right: 20px;
    width:25%;
}
#body-right-column {
    flex: 1;
    padding-left:50px;
    padding-right: 10px;
    width:75%;
}
#cover-estate-logo {
    padding-left: 10px;
    padding-top: 10px;
    max-height: 80px;
    max-width: auto;
}
#cover-estate-no-logo
{
    height: 80px;
}
.cover-epims-logo {
    display: flex;
    padding-top: 80px;
    padding-left: 120px;
    justify-content: center;
    max-height: 75px;
    max-width: auto;
}
#cover-address {
    padding-top: 40px;
    padding-left: 60px;
    font-family: Arial;
    font-size: x-large;
    font-weight: bold;
    color: #5f5f5f;
}
.cover-address-text {
    text-align:center;
}
.cover-close {
    text-align: center;
    padding-top: 20px;
    padding-left: 80px;
    font-family: Arial;
    font-size: x-large;
    font-weight: bold;
    color: #389ABE;
}
#body-right-column {
    padding-top: 0px;
}

.sharedReportListItem {
    color: #1E1515;
    font-family: 'Trebuchet MS';
    font-size: large;
    text-align: left;
    font-weight: normal;
    padding-top:10px;
}

.premium-background {
    background-color: #e9e9e9;
    padding-top:10px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: 'Trebuchet MS';
    font-size: large;
    text-align: left;
    font-weight: normal;
    height:80px
}
.download-button {
    background-color:#e9e9e9;
    position: absolute;
    transform: translate(50%, 50%);
}

.header-logos {
 max-height:100px;
 max-width:auto;   
}

.cover-logo-epims {
    max-height:100px;
    max-width:auto;
}

@media screen and (max-width: 320px) {
    .responsive-h6 {
        font-size: 3vw;
    }
}